import * as React from 'react';
import { Helmet } from 'react-helmet';

import './landing.scss';

const pageStyles = {
  width: '100%',
  height: '100%',
  backgroundColor: '#3D357E',
  background: "url('images/landscape.svg')",
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'auto 100%',
  color: '#232129',
  padding: 0,
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
};

const IndexPage = () => (
  <main style={pageStyles}>
    <Helmet title="Sugarless — Get Free From Sugar" />
    <div className="content">
      <h1 className="heading">
        Your Journey Starts Soon
      </h1>
      <a href="mailto:hello@sugarlessapp.com" className="mail">hello@sugarlessapp.com</a>
    </div>
  </main>
);

export default IndexPage;
